import React, { useEffect } from 'react';
import error from '../res/frame/errorImage.png';

const ErrorPage = () => {
  useEffect(() => {
    document.title = 'Error - Nightmarius';
  }, []);

  return (
    <main>
      <div className='section'>
        <h1>Error 404 - Page not found</h1>
        <h2>This page was either removed or it did never exist</h2>
      </div>
      <img className='section' style={{ maxWidth: 500 }} src={error} alt='errorImage'></img>
    </main>
  );
};
export default ErrorPage;
