import React, { useContext, useState } from 'react';
import Tab from '../../components/tabs/tab';
import { useNavigate } from 'react-router-dom';
import { fetchJson } from '../../helpers';
import { UserContextType } from '../../types';
import Captcha from '../../components/captcha/captcha';
import { UserContext } from '../../constants';

const Login = () => {
  const [captcha, setCaptcha] = useState({ code: '', valid: false });
  const [usernameValid, setUsernameValid] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [stayLogged, setStayLogged] = useState(false);
  const navigate = useNavigate();
  const { setUser } = useContext(UserContext) as UserContextType;

  return (
    <main>
      <h2>Login</h2>
      <Tab titles={['Login', 'Register']}>
        <form action='?' method='POST' id='loginForm'>
          <input
            type='text'
            placeholder='Username'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            maxLength={20}
            required
            autoFocus
          />
          <br />
          <input type='password' placeholder='Password' value={password} onChange={(e) => setPassword(e.target.value)} required />
          <br />
          <label>
            <input type='checkbox' checked={stayLogged} onChange={(e) => setStayLogged(e.target.checked)} />
            Stay logged in
          </label>
          <br />
          <input
            type='submit'
            value='Login'
            onClick={(e) => {
              e.preventDefault();
              login();
            }}
          />
        </form>
        <form action='?' method='POST' id='loginForm'>
          <input
            type='text'
            className={usernameValid ? 'valid' : 'invalid'}
            placeholder='Username'
            value={username}
            onChange={(e) => changeUsername(e.target.value)}
            maxLength={20}
            required
            autoFocus
          />
          <br />
          <input
            type='password'
            className={passwordValid() ? 'valid' : 'invalid'}
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <br />
          <input
            type='email'
            className={emailValid() ? 'valid' : 'invalid'}
            placeholder='E-mail (not required)'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <label>
            <input type='checkbox' checked={stayLogged} onChange={(e) => setStayLogged(e.target.checked)} />
            Stay logged in
          </label>
          <br />
          <Captcha updateCaptcha={(captcha) => setCaptcha(captcha)} />
          <br />
          <input
            type='submit'
            value='Register'
            disabled={!allValid()}
            onClick={(e) => {
              e.preventDefault();
              register();
            }}
          />
        </form>
      </Tab>
    </main>
  );

  function register() {
    if (!allValid()) return;
    fetchJson('register', 'POST', { username, password, email, captcha: captcha.code }, (data) => {
      if (data) {
        setUser({ ...data, favorites: JSON.parse(data.favorites) });
        navigate('/profile');
      }
    });
  }

  function login() {
    //add salt and pepper
    fetchJson('login', 'POST', { username, password }, (data) => {
      //redirect to home page?
      if (stayLogged) {
        localStorage.setItem('username', username);
        //TODO salt and hash here too
        localStorage.setItem('password', password);
      } else {
        sessionStorage.setItem('username', username);
        sessionStorage.setItem('password', password);
      }
      if (data) {
        setUser({ ...data, favorites: JSON.parse(data.favorites) });
        navigate(-1);
      }
    });
  }

  function changeUsername(name: string) {
    setUsername(name);
    if (name.length < 3) {
      setUsernameValid(false);
    } else {
      fetchJson('user/get', 'POST', { username: name }, (data) => setUsernameValid(data.length === 0));
    }
  }

  function passwordValid() {
    return password.length > 7;
  }

  function emailValid() {
    return email.length === 0 || email.match('(.+@.+\\..+)');
  }

  function allValid() {
    return usernameValid && passwordValid() && emailValid() && captcha.valid;
  }
};
export default Login;
