import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ToggleButton from '../../components/toggleButton/toggleButton';
import { backendURL } from '../../constants';
import './wgcount.css';

const WgCount = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [list, setList] = useState(<></>);
  const [lastAmount, setLastAmount] = useState(0);
  const pw = useRef<HTMLInputElement>(null);
  const msg = useRef<HTMLInputElement>(null);
  const price = useRef<HTMLInputElement>(null);
  const [payer2, setPayer] = useState(false);

  const password = new URLSearchParams(useLocation().search).get('p');
  useEffect(() => {
    checkPW();
  }, []);

  if (!loggedIn)
    return (
      <form
        className='centered'
        onSubmit={(e) => {
          e.preventDefault();
          checkPW();
        }}
      >
        <input type='password' maxLength={20} ref={pw} />
        <input type='submit' value='Authenticate' />
      </form>
    );
  return (
    <main>
      <h1>WG Balance</h1>
      <div style={{ padding: '10px' }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            addEntry();
          }}
        >
          <input type='text' ref={msg} placeholder='Purchase description' />
          <> </>
          <input type='number' ref={price} defaultValue={0} />
          <ToggleButton active='Marius' inactive='Marc' onChange={setPayer} />
          <input type='submit' value='Add' className='small' />
        </form>
      </div>
      <div style={{ height: '70vh', overflow: 'auto' }}>{list}</div>
    </main>
  );

  function checkPW() {
    if (password === process.env.REACT_APP_WG_PASS || pw.current?.value === process.env.REACT_APP_WG_PASS) {
      setLoggedIn(true);
      loadHistory();
    }
  }

  function loadHistory() {
    fetch(backendURL + 'wgcount/get')
      .then((response) => response.json())
      .then((data) => showHistory(data));
  }

  function showHistory(data: { description: string; amount: number }[]) {
    setLastAmount(data[data.length - 1].amount);
    const html = (
      <>
        {data.reverse().map((entry, i) => {
          const amount = entry.amount < 0 ? 'Marc ' + -entry.amount : 'Marius ' + entry.amount;
          return <p key={i}>{entry.description + ' ' + amount}</p>;
        })}
      </>
    );
    setList(html);
  }

  async function addEntry() {
    const description = msg.current?.value;
    const change: number = parseInt(price.current?.value as string);
    const amount = payer2 ? lastAmount + change : lastAmount - change;
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ description, amount }),
    };
    fetch(backendURL + 'wgcount/add', request).then(() => {
      loadHistory();
    });
  }
};
export default WgCount;
