export const projectList = [
  {
    id: 'buffering',
    name: 'Buffering',
    description: 'A very fancy buffering animation.',
    mobile: true,
  },
  {
    id: 'circlepacking',
    name: 'Circle Packing',
    description: 'Draw a shape and it will be filled with colorful circles.',
    mobile: true,
  },
  {
    id: 'circleshooter',
    name: 'Circle Shooter',
    description: 'Shoot the shapes to survive.',
  },
  {
    id: 'clock',
    name: 'Clock',
    description: 'A fancy futuristic clock.',
    mobile: true,
  },
  {
    id: 'collatztree',
    name: 'Collatz Tree',
    description: 'A tree that displays the Collatz theorem visually.',
    mobile: true,
  },
  {
    id: 'colorwell',
    name: 'Color Well',
    description: 'Just a infinite well that spews colorfull shapes.',
    mobile: true,
  },
  {
    id: 'converter',
    name: 'Converter',
    description: 'Use this to convert your numbers and codes. Supports ASCII, hexadecimal, decimal, binary and base64.',
    mobile: true,
  },
  {
    id: 'cubelab',
    name: 'Cube Lab',
    description: 'A puzzle game in a world of cubes. Playing with shadows and colors.',
  },
  {
    id: 'fanorona',
    name: 'Fanorona',
    description: 'A two player strategy board game from Madagascar.',
    mobile: true,
  },
  {
    id: 'fractal',
    name: 'Fractal',
    description: 'A fractal that is formed by randomly moving particles and sticking them to the fractal',
    mobile: true,
  },
  {
    id: 'fractaltree',
    name: 'Fractal Tree',
    description: 'A simple reactive colorful fractal tree that is formed using a recursive algorithm.',
    mobile: true,
  },
  {
    id: 'fruitwars',
    name: 'Fruit Wars',
    description: 'Take control of a fruit and conquer all the trees to increase your production and become the only fruit left.',
    mobile: true,
  },
  {
    id: 'gallery',
    name: 'Art Gallery',
    description: 'A gallery of all my art.',
    mobile: true,
  },
  {
    id: 'hangman',
    name: 'Hangman',
    description: 'Play the Hangman word guessing game against your friends or random people online.',
    mobile: true,
  },
  {
    id: 'hexmines',
    name: 'Hexmines',
    description: 'Hexmines is the classic minesweeper game reimagined in a hexagonal grid.',
    mobile: true,
  },
  {
    id: 'mandelbrot',
    name: 'Interactive Mandelbrot Set',
    description:
      'The Mandelbrot set is a fractal defined by a set of complex numbers for which a simple iterative algorithm remains bounded.',
    mobile: true,
  },
  {
    id: 'maze',
    name: 'Maze',
    description:
      'This is a randomly generated maze that is made up of a set of square tiles. You play as the green dot and need to reach the red goal to unlock the next level.',
    mobile: true,
  },
  {
    id: 'nightstand',
    name: 'Nightstand',
    description:
      'A simple model of a nightstand that is rendered using Three.js. You can customize the materials and accessories.',
    mobile: true,
  },
  {
    id: 'pascal',
    name: "Pascal's Triangle",
    description:
      "By coloring the numbers in Pascal's Triangle which are divisible by 2, you get the Sierpiński triangle. Doing this with other divisors leads to multiple different fractals.",
    mobile: true,
  },
  {
    id: 'picevo',
    name: 'Picture Evolution',
    description: 'This is a simple genetic algorithm that evolves a picture using a set of polygons.',
    mobile: true,
  },
  {
    id: 'rago',
    name: 'Rago',
    description: 'Agar.io clone.',
  },
  {
    id: 'snake',
    name: 'Snake',
    description: 'The classic snake game.',
  },
  {
    id: 'snake2',
    name: 'Snake V2',
    description: 'The snake game but better',
  },
  {
    id: 'vampirism',
    name: 'Vampirism',
    description:
      "You play as a vampire. You need to figure out how to get to the coffin before the sun rises. Your blood is poisonous and will kill you if you don't hurry.",
  },
  {
    id: 'vill',
    name: 'Vill',
    description:
      'Explore a procedurally generated world and defeat the enemies within. Solve mysteries to gain power and conquer the islands of vill.',
    mobile: true,
  },
  {
    id: 'voidfencer',
    name: 'Void Fencer',
    description: 'A two player fencing game, but the lights are out and you need a flashlight.',
  },
];
