import React, { useEffect, useState } from 'react';
import { fetchJson } from '../../helpers';
import { User } from '../../types';

const Users = () => {
  useEffect(() => {
    fetchJson('users/get', 'GET', null, (data) => {
      setUsers(data);
    });
  }, []);

  const [users, setUsers] = useState<User[]>([]);
  return (
    <main>
      <h1>Users</h1>
      <table className='table'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Admin</th>
            <th>Email</th>
            <th>IP</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.username}>
              <td>{user.username}</td>
              <td>{user.isAdmin != false ? '✓' : ''}</td>
              <td>{user.email}</td>
              <td>{user.ip}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </main>
  );
};
export default Users;
