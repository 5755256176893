import React, { useEffect, useRef, useState } from 'react';
import { backendURL } from '../../constants';
import { fetchJson } from '../../helpers';
import './stash.css';
import upload from '../../res/Upload.png';

const Stash = () => {
  const textarea = useRef<HTMLTextAreaElement>(null);
  const [note, setNote] = useState('');
  const [files, setFiles] = useState<string[]>([]);

  useEffect(() => {
    const dropzone = document.getElementById('dropzone') as HTMLDivElement;
    let dragTimer: number | undefined;
    window.addEventListener('dragover', (e) => {
      const dt = e.dataTransfer as DataTransfer;
      if (dt.types && (dt.types.indexOf ? dt.types.indexOf('Files') != -1 : dt.types.includes('Files'))) {
        dropzone.style.display = 'block';
        window.clearTimeout(dragTimer);
      }
    });
    window.addEventListener('dragleave', () => {
      dragTimer = window.setTimeout(function () {
        if (dropzone) dropzone.style.display = 'none';
      }, 25);
    });
    window.addEventListener('drop', () => {
      dragTimer = window.setTimeout(function () {
        if (dropzone) dropzone.style.display = 'none';
      }, 25);
    });
    setInterval(refreshStash, 1000);
  }, []);

  return (
    <main>
      <h2>Stash</h2>
      <div id='files'>
        {files.map((file) => {
          return (
            <div key={file}>
              <a href={backendURL + 'stash/get/' + file} target='_self'>
                {file}
              </a>
              <input type='button' onClick={() => fetchJson('stash/delete', 'POST', { file })} name='delete' value='❌' />
            </div>
          );
        })}
      </div>
      <textarea
        id='notes'
        ref={textarea}
        value={note}
        onChange={(e) => setNote(e.target.value)}
        onBlur={() => fetchJson('stash/note/post', 'POST', { note })}
      />

      <div id='dropzone'>
        <form>
          <input
            id='file'
            type='file'
            name='myfile'
            onChange={(e) => {
              if (e.target.files) uploadFile(e.target.files[0]);
            }}
          />
        </form>
      </div>
      <form id='fileButton'>
        <input
          id='fileInput'
          type='file'
          name='myfile'
          onChange={(e) => {
            if (e.target.files) uploadFile(e.target.files[0]);
          }}
        />
        <img id='UploadPic' src={upload} alt='UploadImage' />
      </form>
      <br />
    </main>
  );

  function refreshStash() {
    if (document.activeElement !== textarea.current) {
      fetchJson('stash/note/get', 'GET', null, (data) => {
        setNote(data.note);
      });
      fetchJson('stash/get', 'GET', null, (data) => {
        setFiles(data);
      });
    }
  }

  function uploadFile(file: File) {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      fetch(backendURL + 'stash/upload', {
        method: 'POST',
        body: formData,
      });
    }
  }
};
export default Stash;
