import React, { useState } from 'react';
import './toggleButton.css';

const ToggleButton = ({ active, inactive, onChange }: { active: string; inactive: string; onChange: (boolean) => void }) => {
  const [on, setOn] = useState(false);
  return (
    <>
      <div
        className='toggleButton'
        onClick={() => {
          setOn(!on);
          onChange(!on);
        }}
      >
        <span>{on ? active : inactive}</span>
        <div className={on ? 'toggleOn' : ''}>⟷</div>
      </div>
    </>
  );
};
export default ToggleButton;
