import React from 'react';

interface ModalProps {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  message: string;
  onClose?: () => void;
}

function Modal({ isOpen, setOpen, message, onClose }: ModalProps) {
  function close() {
    setOpen(false);
    if (onClose) onClose();
  }

  return (
    <>
      {isOpen && (
        <div className='fullscreen'>
          <div className='modal'>
            <input type='button' value='❌' onClick={close} />
            <p>{message}</p>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
