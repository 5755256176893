import React, { useState } from 'react';
import './home.css';
import CircleShooterRecord from '../../res/CircleShooter.png';
import HexMinesRecord from '../../res/HexMines.png';
import logo from '../../res/frame/LogoAnim.min.svg';
import { projectList } from '../projects/projectList';
import { rand } from '../../helpers';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const quotes: string[] = [
    'No one is mightier than their creator.\nNot even god.',
    "It's easier to fool people than to convince them that they have been fooled.",
    'Believe in men, not the gods they created.',
    'Joy is not the purpose of life.\nLife is the purpose of joy.',
    'You are taught to remember the rules.\nYet it is the details which make life worth living.',
    'There are at least as many truths as there are people.',
    "Everything seems impossible, until it's done.",
    'Take away any comfort you have for a day.\nYou will appreciate it more the next day.',
    "In dark times a blind person is the best guide.\nIn bright times however, it is foolish to trust a guide who can't see.",
    "Truth doesn't need to be believed.",
    "You don't need to know the right answer\nto recognize a wrong answer.",
    'It is difficult to free fools from the chains they revere.',
    'You are not controlling the storm,\nand you are not lost in it. You are the storm.',
    'Not everything that counts can be counted\n and not everything that can be counted truly counts.',
    'Everyone can create it. It can change everything.\nIt controls everyone. Thougths.',
    'Nothing is true, everything is permitted.',
    'Seek first to understand, before being understood.',
    'The reward of suffering is experience.',
    'Find yourself, lose yourself, then find others.',
    'Death is inevitable. Enjoy life.',
    'He who desires, but acts not, breeds pestilence.',
    "I may be on the side of the angels, but that doesn't make me one of them.",
    'No wind, no waves',
    'Why make things difficult\nif it does not have to be?',
    'Bad things never walk alone.',
    'We always have a choice,\nyet we never really choose.',
    'Deep doubts, deep wisdom;\nsmall doubts, little wisdom.',
    "In the midst of great joy, do not promise anyone anything.\nIn the midst of great anger, do not answer anyone's letter.",
    'Climb mountains to see lowlands.',
    'Once bitten by a snake, she is scared all her life\nat the mere sight of a rope.',
    'It is later than you think.',
    "If your strength is small, don't carry heavy burdens.\nIf your words are worthless, don't give advice.",
    'I hear and I forget, I see and I remember.\nI do and I understand.',
    'Who asks is a fool for five minutes,\nbut who does not ask remains a fool forever.',
    'A closed mind is like a closed book;\njust a block of wood.',
    'Be not afraid of going slowly,\nbe afraid only of standing still.',
    'Not the cry, but the flight of the wild duck,\nleads the flock to fly and follow.',
    'To build it took one hundred years;\nto destroy it one day.',
    'Boredom gets you to enjoy stupid things.',
    'If you are patient in one moment of anger,\nyou will escape a hundred days of sorrow.',
  ];
  const day1: number = Math.floor(Date.now() / 86400000);
  const day2: number = Math.floor(Date.parse('01 JUL 1997') / 86400000);
  const day: number = Math.floor(Date.now() / 86400000);
  console.log(day1 - day2);
  const todaysQuote: string = quotes[day % quotes.length];

  const [latestVideo, setLatestVideo] = useState('');
  const navigate = useNavigate();
  const cid = 'UCPwK6-HcdbKknK4TngFkDEA';
  const channelURL = encodeURIComponent(`https://www.youtube.com/feeds/videos.xml?channel_id=${cid}`);
  const reqURL = `https://api.rss2json.com/v1/api.json?rss_url=${channelURL}`;
  fetch(reqURL, { method: 'GET' })
    .then((response) => response.json())
    .then((result) => {
      const link = result.items[0].link;
      //const id = link.substr(link.indexOf('=') + 1);
      const id = 'J_UzfVg7AKs';
      setLatestVideo(`https://youtube.com/embed/${id}?autoplay=0`);
    })
    .catch((error) => console.log('error', error));

  return (
    <main>
      <header style={{ background: 'url(/frame/forest1.png) 0 0/100% auto no-repeat fixed' }}>
        <button id='logo' onClick={openRandomProject}>
          <img src={logo} alt='logo' />
        </button>
        <p id='quote'>{todaysQuote}</p>
      </header>
      <h2>Checkout my game</h2>
      <div id='vill'>
        <a href='https://n1ghtmarius.itch.io/vill'>
          <img src='/img/vill/VillCoverImg.png' alt='Vill Cover Img' width='250px'></img>
        </a>
        <div>
          <h3>
            <a href='https://n1ghtmarius.itch.io/vill'>Vill</a>
          </h3>
          <p>
            by <a href='https://n1ghtmarius.itch.io/'>Nightmarius</a>
          </p>
          <p>An action adventure souls-like with procedurally generated levels and puzzles.</p>
          <a
            href='https://n1ghtmarius.itch.io/vill/purchase'
            target='popup'
            onClick={() => window.open('https://n1ghtmarius.itch.io/vill/purchase', 'popup', 'width=700,height=500')}
          >
            Download Now
          </a>
          <p>
            <a href='/vill'>Devlog</a>
          </p>
        </div>
      </div>
      <br />
      <h2>Can you beat me?</h2>
      <a href='/hexmines'>
        <img
          src={HexMinesRecord}
          alt='Hex'
          className='medium'
          style={{ clipPath: 'polygon(0 27%,0 73%,50% 100%,100% 73%,100% 27%,50% 0)' }}
        />
      </a>
      <a href='/circleshooter'>
        <img src={CircleShooterRecord} alt='Circle' className='medium' />
      </a>
      <h2>Checkout my youtube!</h2>
      <iframe
        id='youtube2'
        title='recent video'
        src={latestVideo}
        style={{ width: '100%', maxHeight: '600px', height: '60vh' }}
        frameBorder='0'
        allow='accelerometer; encrypted-media; gyroscope; picture-in-picture'
        allowFullScreen
      ></iframe>
    </main>
  );

  function openRandomProject() {
    const randomProject = projectList[rand(0, projectList.length)].id;
    navigate('/' + randomProject);
  }
};
export default Home;
