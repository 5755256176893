import React, { useEffect } from 'react';

const Loading = () => {
  let canvas: HTMLCanvasElement;
  let c: CanvasRenderingContext2D;
  const cW = 500;
  const cH = 500;

  useEffect(() => {
    canvas = document.getElementsByTagName('canvas')[0] as HTMLCanvasElement;
    canvas.width = cW;
    canvas.height = cH;
    c = canvas.getContext('2d') as CanvasRenderingContext2D;
    setInterval(buf, 30);
  }, []);

  const lines = 15;
  let rot = 0;
  function buf() {
    rot = ((Date.now() / 1000) % Math.PI) * 2;
    c.lineCap = 'round';
    c.clearRect(0, 0, cW, cH);
    for (let i = 0; i < lines; i++) {
      c.beginPath();
      const angle1 = rot % (Math.PI * 2);
      const angle2 = (rot % (Math.PI * 2)) + Math.PI;
      c.arc(
        cW / 2,
        cH / 2,
        ((i / lines) * cW) / 2,
        angle1 - Math.sin((i / lines) * Math.PI * 2 - angle1) * 3,
        angle2 + Math.sin((i / lines) * Math.PI * 2 - angle2) * 3
      );
      c.lineWidth = cW / 12 / lines;
      c.strokeStyle = 'hsla(' + ((i / lines) * 360 + 20) + ', 100%, 50%,' + 1 + ')';
      c.stroke();
    }
  }
  return (
    <main>
      <h1>Loading...</h1>
      <canvas className='reactive' style={{ maxWidth: 500 }} />
    </main>
  );
};
export default Loading;
