import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { guest, UserContext } from '../../constants';
import { fetchJson } from '../../helpers';
import { UserContextType } from '../../types';

const Profile = () => {
  const { user, setUser } = useContext(UserContext) as UserContextType;
  const [email, setEmail] = useState(user.email);
  const navigate = useNavigate();

  return (
    <main>
      <h1>{user.username}</h1>
      <p>{user.email}</p>
      <br />
      <p>Profile page is still being built and will be added soon...</p>
      {/*<input type='button' onClick={changeEmail} value='Change Username' />
      <input type='button' onClick={changeEmail} value='Change Password' />
      <input type='email' value={email} />
      <input type='button' onClick={changeEmail} value='Change Email' />
      <p>{user.isAdmin != null ? '✓' : ''}</p>*/}
      <input type='button' onClick={logout} value='Logout' />
    </main>
  );
  function changeEmail() {
    console.log(user);
  }
  function logout() {
    console.log(user);
    fetchJson('logout', 'POST', {}, (data) => {
      console.log(data);
      if (data) {
        setUser(guest);
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
      }
    });
  }
};
export default Profile;
