import React, { useEffect, useState } from 'react';
import { backendURL } from '../../constants';
import { fetchJson } from '../../helpers';

const Captcha = ({ updateCaptcha }: { updateCaptcha: (params: { code: string; valid: boolean }) => void }) => {
  const [valid, setValid] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    updateCaptcha({ code, valid });
  }, [code, valid]);

  return (
    <>
      <img
        src={backendURL + 'captcha.png'}
        style={{ cursor: 'pointer' }}
        onClick={(e) => reloadCaptcha(e.target as HTMLImageElement)}
      ></img>
      <br />
      <input
        type='text'
        className={valid ? 'valid' : 'invalid'}
        value={code}
        placeholder='Enter the 7 characters'
        onChange={(e) => checkValid(e.target.value.toUpperCase())}
      />
    </>
  );

  function checkValid(code: string) {
    setCode(code);
    fetchJson('captcha/check', 'POST', { code }, (data) => {
      setValid(data);
    });
  }

  function reloadCaptcha(img: HTMLImageElement) {
    img.src = backendURL + 'captcha.png';
    setValid(false);
  }
};
export default Captcha;
