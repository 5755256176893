import React, { useState } from 'react';
import './tab.css';

const Tab = (props: { children: JSX.Element[]; titles: string[] }) => {
  const [selected, select] = useState(0);
  return (
    <div className='tab'>
      <div className='tabHeader'>
        {props.titles.map((e, i) => (
          <input type='button' className={selected === i ? 'selected' : ''} value={e} key={i} onClick={() => select(i)} />
        ))}
      </div>
      <div className='tabContent'>{props.children[selected]}</div>
    </div>
  );
};
export default Tab;
