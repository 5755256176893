import { backendURL } from './constants';
import { Mouse, Point } from './types';

/**
 * Returns a random integer between from (inclusive) and to (exclusive)
 */
export function rand(from: number, to: number) {
  return Math.floor(Math.random() * (to - from)) + from;
}

/**
 * Calculate the distance between two points
 */
export function distance(point1: Point, point2: Point) {
  return Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point2.y - point1.y, 2));
}

/**
 * Calculate the distance between a coordinate and a point
 */
export function distanceTo(x: number, y: number, point: Point) {
  return Math.sqrt(Math.pow(x - point.x, 2) + Math.pow(point.y - y, 2));
}

/**
 * Submit a score to the backend for a specific game
 */
export function submitScore(game: string, username: string | undefined, score: number, order: 'ASC' | 'DESC') {
  const secret = process.env.REACT_APP_SCORE_SECRET as string;
  const millis = Date.now();
  sha256(secret + username + score + millis).then((hex) =>
    fetchJson('scores/add', 'POST', { game, username, score, millis, hex, order })
  );
}

/**
 * Hash a string using SHA256 into a hex string
 */
export async function sha256(message: string): Promise<string> {
  const buffer = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(message));
  return Array.from(new Uint8Array(buffer))
    .map((b) => ('00' + b.toString(16)).slice(-2))
    .join('');
}

/**
 * Execute a call to the backend
 */
export function fetchJson(path: string, method: string, data?: unknown, callback?: (data) => void) {
  let f;
  if (method === 'GET') {
    const request = {
      method,
      credentials: 'include' as RequestCredentials,
    };
    f = fetch(backendURL + path, request);
  } else {
    const request = {
      method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      credentials: 'include' as RequestCredentials,
    };
    f = fetch(backendURL + path, request);
  }
  if (callback) {
    f.then((response) => response.json()).then(callback);
  }
}

/**
 * Adds mouse and touch listeners to the canvas.
 */
export function addMouseListeners(canvas: HTMLCanvasElement, widthRatio: number, mouse: Mouse, onChange?: () => void) {
  if (widthRatio < 1) widthRatio = 1;
  canvas.addEventListener('mousemove', function (e) {
    setMousePos(e.pageX, e.pageY);
  });
  canvas.addEventListener('mousedown', () => {
    mouse.pressed = true;
    if (onChange) onChange();
  });
  canvas.addEventListener('mouseup', () => {
    mouse.pressed = false;
    if (onChange) onChange();
  });
  canvas.addEventListener('drag', function (e) {
    setMousePos(e.pageX, e.pageY);
  });
  canvas.addEventListener('touchmove', function (e) {
    setMousePos(e.touches[e.touches.length - 1].pageX, e.touches[e.touches.length - 1].pageY);
  });
  canvas.addEventListener('touchstart', (e) => {
    mouse.pressed = true;
    setMousePos(e.touches[e.touches.length - 1].pageX, e.touches[e.touches.length - 1].pageY);
    if (onChange) onChange();
  });
  canvas.addEventListener('touchend', () => {
    mouse.pressed = false;
    if (onChange) onChange();
  });

  function setMousePos(x: number, y: number) {
    mouse.x = (x - canvas.offsetLeft) * widthRatio;
    mouse.y = (y - canvas.offsetTop) * widthRatio;
  }
}
