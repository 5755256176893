import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../../constants';
import Loading from '../../pages/loading';
import { UserContextType } from '../../types';

interface RouteProps {
  adminOnly?: boolean;
}

const PrivateRoute = (props: RouteProps) => {
  const { user } = useContext(UserContext) as UserContextType;
  if (user.username == '') {
    return <Loading />;
  }
  if (props.adminOnly) {
    return user.isAdmin ? <Outlet /> : <Navigate to='/login' />;
  }
  return user.loggedIn ? <Outlet /> : <Navigate to='/login' />;
};
export default PrivateRoute;
